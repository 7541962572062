<style scoped>
.listWorkouts {
  height: 550px;
  overflow: scroll;
}

.sticky {
  position: sticky;
  top: 0px;
}

.listExercices {
  height: 550px;
  overflow: scroll;
}

.containerPartieWorkout {
  height: 800px;
  overflow: scroll;
}

.separator {
  color: #000000;
  height: 3px;
}

.containerPartieWorkout {
  margin-bottom: 10px;
  border-radius: 5px;
}

.cardDetailFood {
  min-height: 600px;
}

.boxlist {
  border-bottom: 1px solid #dfe0e1;
  cursor: pointer;
}

.boxIngredient {
  border: 1px solid #dfe0e1;
  padding: 10px;
  margin-bottom: 5px;
}

.boxlist:hover {
  background-color: #ededed;
}

.smalltext {
  font-size: 10px;
}
</style>
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import firebase from "firebase/compat/app";
import "firebase/compat/storage";

/**
 * Profile component
 */
export default {
  components: {
    Layout,
    PageHeader,
  },
  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    majuscule: function (value) {
      if (!value) return "";
      return (value = value.toString().toUpperCase());
    },
  },
  page: {
    title: "Basic Programs",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  mounted() {
    this.getAllBasicWorkouts();
    this.getAllBasicPrograms();
  },
  data() {
    return {
      title: "Basic Programs",
      saving_modification: false,
      saving_photo: false,
      saving_photo_it: false,
      saving_photo_fr: false,
      saving_video_it: false,
      adding_program: false,
      deleting_program: false,
      transloading_photo: false,
      add_program: false,
      workouts: [],
      forceUpdateList: 1,
      programs: [],
      program: null,
      uploadValue: 0,
      modelConfig: {
        type: 'number',
      },
      muscles: [
        "Legs",
        "Abs",
        "Chest",
        "Back",
        "Shoulders",
        "Biceps",
        "Triceps",
        "Cardio",
      ],
      niveaux: ["Easy", "Medium", "Hard"],
      materiels: [
        "Bodyweight",
        "Dumbbells or Bottles",
        "Kettlebell",
        "Barbell",
        "Jumping rope",
        "Weight plate",
        "Tapis roulant",
        "Elliptical",
        "Stepper",
        "Bike",
        "Stairs",
        "Rower",
        "Fitball",
        "Elastic",
        "Bench",
        "Gym",
        "Medecine Ball",
        "Multifunction",
        "Ankles",
        "TRX",
        "Outdoor",
        "Step",
        "Chair",
      ],
      objectifs: [
        "anticellulite",
        "tonification",
        "brulegraisse",
        "muscle",
        "force",
        "affinertaille",
        "definition",
      ],
      langues: ["it", "fr", "us", "es", "br"],
      optionsTypeDay: ["RESTDAY", "TRAINING"],
      items: [
        {
          text: "Basic Programs",
        },
        {
          text: "basicprograms",
          active: true,
        },
      ],
    };
  },
  methods: {
    setTimestampDate(){
      var date = document.getElementById("date").value;
      var timestamp = new Date(date).getTime();
      this.program.data.date = timestamp;
    },
    timestampToDate(timestamp){
      return new Date(timestamp).toISOString().substr(0, 10);
    },
    async transloadPhoto() {
      this.transloading_photo = true;

      var url = this.program.data.photo.original;

      var data = {
        id: this.program.id,
        url: url,
        type: "programs",
      };

      var transloadPhoto = firebase.functions().httpsCallable("transloadPhoto");
      transloadPhoto(data).then((result) => {
        // Read result of the Cloud Function.
        console.log(result);
        this.program.data.photo = result.data.photo;
        firebase
          .firestore()
          .collection("basicprograms")
          .doc(data.id)
          .update(this.program.data);
        this.transloading_photo = false;
      });
    },
    async transferPhoto() {
      this.saving_photo = true;

      var newPhoto = document.getElementById("newPhoto").files[0];
      console.log(newPhoto);
      var type = newPhoto.name.split(".").pop();
      var filename =
        "programs/" + this.program.id + "-" + Date.now() + "." + type;

      const storageRef = firebase
        .app()
        .storage("gs://gabriellavicofitness")
        .ref(filename)
        .put(newPhoto);
      storageRef.on(
        `state_changed`,
        (snapshot) => {
          this.uploadValue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          console.log(error.message);
        },
        () => {
          this.uploadValue = 100;
          storageRef.snapshot.ref.getDownloadURL().then((url) => {
            var db = firebase.firestore();
            db.collection("basicprograms")
              .doc(this.program.id)
              .update({
                photo: {
                  original: url,
                  optimized: null,
                  thumbnail: null,
                },
              })
              .then(() => {
                this.program.data.photo = {
                  original: url,
                  optimized: null,
                  thumbnail: null,
                };
                this.saving_photo = false;
                this.uploadValue = 0;
                document.getElementById("newPhoto").value = "";
              })
              .catch((error) => {
                console.error("Error writing document: ", error);
              });
          });
        }
      );
    },
    async transferPhotoIT() {
      this.saving_photo_it = true;
      var newPhoto = document.getElementById("newPhotoIT").files[0];
      console.log(newPhoto);
      var type = newPhoto.name.split(".").pop();
      var filename =
        "programs/" + this.program.id + "-IT-" + Date.now() + "." + type;

      const storageRef = firebase
        .app()
        .storage("gs://gabriellavicofitness")
        .ref(filename)
        .put(newPhoto);
      storageRef.on(
        `state_changed`,
        (snapshot) => {
          this.uploadValue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          console.log(error.message);
        },
        () => {
          this.uploadValue = 100;
          storageRef.snapshot.ref.getDownloadURL().then((url) => {
            var db = firebase.firestore();
            db.collection("basicprograms")
              .doc(this.program.id)
              .update({
                photo_it: url,
              })
              .then(() => {
                this.program.data.photo_it = url;
                this.saving_photo_it = false;
                this.uploadValue = 0;
                document.getElementById("newPhotoIT").value = "";
              })
              .catch((error) => {
                console.error("Error writing document: ", error);
              });
          });
        }
      );
    },
    async transferVideoIT() {
      this.saving_video_it = true;
      var newVideo = document.getElementById("newVideoIT").files[0];
      var type = newVideo.name.split(".").pop();
      var filename =
        "programs/" + this.program.id + "-videoIT-" + Date.now() + "." + type;

      const storageRef = firebase
        .app()
        .storage("gs://gabriellavicofitness")
        .ref(filename)
        .put(newVideo);
      storageRef.on(
        `state_changed`,
        (snapshot) => {
          this.uploadValue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          console.log(error.message);
        },
        () => {
          this.uploadValue = 100;
          storageRef.snapshot.ref.getDownloadURL().then((url) => {
            var db = firebase.firestore();
            db.collection("basicprograms")
              .doc(this.program.id)
              .update({
                video_it: url,
              })
              .then(() => {
                this.program.data.video_it = url;
                this.saving_video_it = false;
                this.uploadValue = 0;
                document.getElementById("newVideoIT").value = "";
              })
              .catch((error) => {
                console.error("Error writing document: ", error);
              });
          });
        }
      );
    },
    saveModificationsProgram() {
      this.saving_modification = true;

      var db = firebase.firestore();
      db.collection("basicprograms")
        .doc(this.program.id)
        .update(this.program.data)
        .then(() => {
          console.log("Document successfully written!");
          this.saving_modification = false;
        })
        .catch((error) => {
          console.error("Error writing document: ", error);
        });
    },
    addNewProgram() {
      this.adding_program = true;
      var db = firebase.firestore();
      db.collection("basicprograms")
        .doc(this.program.id)
        .set(this.program.data)
        .then(() => {
          this.adding_program = false;
          this.add_program = false;
          this.programs = [];
          this.program = null;
          this.getAllBasicPrograms();
          console.log("Document successfully written!");
        })
        .catch((error) => {
          console.error("Error writing document: ", error);
        });
    },
    getPhotoProgram(program) {
      if (program.data.photo.original != null)
        return program.data.photo.original;
      else return require("@/assets/images/nophoto.png");
    },
    getPhotoProgramThumb(program) {
      if (program.data.photo.thumbnail != null)
        return program.data.photo.thumbnail;
      else return require("@/assets/images/nophoto.png");
    },
    searchProgram() {
      var search = document.getElementById("searchprogram").value.toLowerCase();
      var niveau = document.getElementById("searchNiveau").value;
      //filtrer en javascript

      document.querySelectorAll("[data-type='program']").forEach((element) => {
        var id_program = element.dataset.id;
        //On récupère le JSON de l'ingredient avec son id
        let program = this.programs.find((el) => el.id === id_program);

        //On regarde si la chaine de caractère est comprise dans le nom d'une des langues pour afficher ou masquer
        if (
          (program.data.description.it.nom.toLowerCase().includes(search) ||
            program.data.description.fr.nom.toLowerCase().includes(search) ||
            program.data.description.us.nom.toLowerCase().includes(search) ||
            search == "") &&
          (program.data.niveaux == niveau || niveau == "all")
        ) {
          console.log("block");
          element.style = "display: block";
        } else {
          console.log("none");
          element.style = "display: none";
        }
      });
    },
    getAllBasicWorkouts() {
      var db = firebase.firestore();
      db.collection("basicworkouts")
        .orderBy("description.it.nom")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            console.log("ref: " + JSON.stringify(doc.ref))
            this.workouts.push({
              value: doc.ref,
              text:
                doc.data().description.it.nom +
                " ( Level " +
                doc.data().niveau_workout +
                " - " +
                doc.data().duree_workout +
                "min )",
            });
          });
        });
    },
    getAllBasicPrograms() {
      var db = firebase.firestore();
      db.collection("basicprograms")
        .orderBy("description.it.nom")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.programs.push({ id: doc.id, data: doc.data() });
          });
        });
    },
    async setProgram(program) {
      this.add_program = false;
      this.program = program;
    },
    showAddProgram() {
      var json_program = {
        photo: {
          original: null,
          optimized: null,
          thumbnail: null,
        },
        description: {
          it: {
            nom: "",
            nom_affiche: "",
            description: "",
          },
          fr: {
            nom: "",
            nom_affiche: "",
            description: "",
          },
          us: {
            nom: "",
            nom_affiche: "",
            description: "",
          },
          es: {
            nom: "",
            nom_affiche: "",
            description: "",
          },
          br: {
            nom: "",
            nom_affiche: "",
            description: "",
          },
        },
        days: [],
        status: {
          it: false,
          fr: false,
          us: false,
          es: false,
          br: false,
        },
        date: new Date().toISOString().slice(0, 10),
        niveaux: "Easy",
        duree: 0,
        jours_entrainements: 0,
        jours_repos: 0,
        materiel_training: [],
        muscles_training: [],
        objectif_training: [],
      };

      this.program = {
        type: "new",
        id: String(Date.now()),
        data: json_program,
      };

      this.add_program = true;
    },
    getFlag(key) {
      return (
        "<img src='https://flagcdn.com/h20/" + key + ".png' height='12' />"
      );
    },
    hideModal() {
      this.$refs["delete-modal"].hide();
    },
    showModal() {
      this.$refs["delete-modal"].show();
    },
    deleteProgram() {
      this.deleting_program = true;
      var db = firebase.firestore();
      db.collection("basicprograms")
        .doc(this.program.id)
        .delete()
        .then(() => {
          this.$refs["delete-modal"].hide();
          this.deleting_program = false;
          this.add_program = false;
          this.programs = [];
          this.program = null;
          this.getAllBasicPrograms();
          console.log("Document successfully deleted!");
        })
        .catch((error) => {
          console.error("Error removing document: ", error);
        });
    },
    addDayProgram() {
      var day = {
        type_journee: "RESTDAY",
        workout: null,
      };
      this.program.data.days.push(day);
    },
    deleteDayList(key) {
      this.program.data.days.splice(key, 1);
      this.forceUpdateList++;
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <b-modal ref="delete-modal" hide-footer title="Delete program">
      <div class="d-block text-center">
        <h3>Are you sure ?!</h3>
        <p>This action is irreversible</p>
      </div>
      <div class="d-grid gap-2">
        <b-button
          class="mt-3"
          variant="outline-danger"
          block
          @click="deleteProgram"
        >
          <span
            v-if="deleting_program"
            class="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"
          ></span>
          Yes, delete definitely</b-button
        >
        <b-button
          class="mt-2"
          variant="outline-warning"
          block
          @click="hideModal"
          >No, close</b-button
        >
      </div>
    </b-modal>

    <div class="container-fluid">
      <div class="row">
        <div class="col-3 pt-2 pb-0 card" style="min-height: 650px">
          <div class="mb-2">
            <div class="d-grid gap-2">
              <button
                class="btn btn-primary"
                type="button"
                @click="showAddProgram()"
              >
                + Add Program
              </button>
            </div>
            <hr />
          </div>
          <div class="mb-0">
            <input
              type="text"
              class="form-control"
              id="searchprogram"
              placeholder="Search program..."
              @keyup="searchProgram()"
            />
            <select
              class="form-select mt-2"
              aria-label="Default select example"
              @change="searchProgram()"
              id="searchNiveau"
            >
              <option value="all" selected>Tous les niveaux</option>
              <option
                v-for="niveau in niveaux"
                :key="'niveausearch' + niveau"
                :value="niveau"
              >
                {{ niveau }}
              </option>
            </select>
            <div class="alert alert-secondary mt-2 px-2 py-1" role="alert">
              <small>{{ programs.length }} programs</small>
            </div>
            <div class="listPrograms">
              <div
                v-for="(program, key) in programs"
                :key="'program' + key"
                :id="'programid' + key"
                :data-id="program.id"
                data-type="program"
                @click="setProgram(program)"
              >
                <div class="container boxlist px-0 py-2">
                  <div class="row">
                    <div class="col-auto">
                      <img
                        width="50px"
                        :src="getPhotoProgramThumb(program)"
                        :key="'photothumb' + program.id"
                        alt
                        class="img-thumbnail"
                      />
                    </div>
                    <div class="col">
                      <p class="fw-bold mb-0">
                        {{ program.data.description.it.nom }}
                      </p>
                      <p class="fw-light smalltext mb-0">
                        Level {{ program.data.niveaux }} -
                        {{ program.data.duree }} jours
                      </p>
                      <div>
                        <div
                          v-for="(value, name, key) in program.data.description"
                          :key="'infodescription' + key"
                          class="d-inline p-1"
                        >
                          <img
                            :src="'https://flagcdn.com/h20/' + name + '.png'"
                            height="12"
                            :style="
                              program.data.description[name].nom == '' ||
                              program.data.description[name].nom == null
                                ? 'opacity: 0.3'
                                : 'opacity: 1'
                            "
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-9">
          <div class="card cardDetailFood">
            <div v-if="program == null" class="text-center">Select program</div>
            <div v-if="program != null" class="p-3">
              <div class="container px-0 py-2">
                <div class="row">
                  <div class="col-12">
                    <div class="container">
                      <div class="row">
                        <div class="col-8">
                          <h1 class="mb-0">
                            {{ program.data.description.it.nom }}
                          </h1>
                          <small>Program #{{ program.id }}</small>
                        </div>
                        <div class="col-4">
                          <div class="d-grid gap-2">
                            <button
                              type="button"
                              class="btn btn-success btn-lg float-end"
                              @click="saveModificationsProgram()"
                              v-if="program.type != 'new'"
                            >
                              <span
                                v-if="saving_modification"
                                class="spinner-grow spinner-grow-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              Save modifications
                            </button>
                            <b-button
                              id="show-btn"
                              class="float-end"
                              variant="danger"
                              @click="showModal"
                              v-if="program.type != 'new'"
                              >Delete</b-button
                            >
                            <button
                              type="button"
                              class="btn btn-success btn-lg float-end"
                              @click="addNewProgram()"
                              v-if="program.type == 'new'"
                            >
                              <span
                                v-if="saving_modification"
                                class="spinner-grow spinner-grow-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              Add Program
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div class="container px-0 py-2">
                      <div class="row">
                        <b-tabs content-class="mt-3">
                          <b-tab
                            v-for="(langue, key) in program.data.description"
                            :key="'descriptiontab-' + key"
                            :active="key === 'it'"
                          >
                            <template slot="title">
                              <img
                                :src="'https://flagcdn.com/h20/' + key + '.png'"
                                height="12"
                              />
                              {{ key }}
                            </template>

                            <div class="mb-3">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                :id="'status_' + key"
                                v-model="program.data.status[key]"
                              />

                              <label :for="'nom_' + key" class="form-label"
                                >Publish ?</label
                              >
                            </div>
                            <div class="mb-3">
                              <label class="form-label">Nom*</label>
                              <input
                                type="text"
                                class="form-control"
                                :id="'programnom_' + key"
                                v-model="program.data.description[key].nom"
                              />
                            </div>
                            <div class="mb-3">
                              <label class="form-label">Nom affiché*</label>
                              <input
                                type="text"
                                class="form-control"
                                :id="'programnomaffiche_' + key"
                                v-model="
                                  program.data.description[key].nom_affiche
                                "
                              />
                            </div>
                            <div class="mb-3">
                              <label :for="'program_' + key" class="form-label"
                                >Description*</label
                              >
                              <textarea
                                class="form-control"
                                :id="'programdescription_' + key"
                                rows="3"
                                v-model="
                                  program.data.description[key].description
                                "
                              ></textarea>
                            </div>
                          </b-tab>
                        </b-tabs>
                      </div>
                    </div>
                    <hr />
                    <div class="container alert alert-warning">
                      <div class="row">
                        <div class="col-auto">
                          <img
                            width="200px"
                            :src="getPhotoProgram(program)"
                            :key="'photo' + program.id"
                            alt
                            class="img-thumbnail"
                          />
                        </div>
                        <div class="col">
                          <ul>
                            <li>
                              <small
                                >Original:
                                <a
                                  :href="program.data.photo.original"
                                  target="_blank"
                                  >{{ program.data.photo.original }}</a
                                ></small
                              >
                            </li>
                            <li>
                              <small
                                >Optimized:
                                <a
                                  :href="program.data.photo.optimized"
                                  target="_blank"
                                  >{{ program.data.photo.optimized }}</a
                                ></small
                              >
                            </li>
                            <li>
                              <small
                                >Thumbnail:
                                <a
                                  :href="program.data.photo.thumbnail"
                                  target="_blank"
                                  >{{ program.data.photo.thumbnail }}</a
                                ></small
                              >
                            </li>
                          </ul>
                          <div
                            class="d-grid gap-2 mt-2"
                            v-if="program.type != 'new'"
                          >
                            <label for="formFile" class="form-label"
                              >Change photo:</label
                            >
                            <div class="row">
                              <div class="col">
                                <input
                                  class="form-control"
                                  type="file"
                                  id="newPhoto"
                                />
                                <progress
                                  v-if="saving_photo"
                                  id="progress"
                                  :value="uploadValue"
                                  max="100"
                                ></progress>
                              </div>
                              <div class="col d-grid gap-2">
                                <button
                                  class="btn btn-primary"
                                  type="button"
                                  id="uploadPhoto"
                                  @click="transferPhoto()"
                                >
                                  <span
                                    v-if="saving_photo"
                                    class="spinner-grow spinner-grow-sm"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                  Update photo
                                </button>
                                <div class="alert alert-secondary" role="alert">
                                  <i class="fas fa-exclamation-circle"></i>
                                  Dimension 1000x400
                                </div>
                              </div>
                              <div class="col">
                                <div
                                  v-if="program.data.photo.original != null"
                                  class="d-grid gap-2"
                                >
                                  <button
                                    class="btn btn-info"
                                    type="button"
                                    id="uploadPhoto"
                                    @dblclick="transloadPhoto()"
                                  >
                                    <span
                                      v-if="transloading_photo"
                                      class="spinner-grow spinner-grow-sm"
                                      role="status"
                                      aria-hidden="true"
                                    ></span>
                                    Transload photo
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div class="container alert alert-warning">
                      <div class="row">
                        <div class="col-auto">
                          <img
                            width="200px"
                            :src="program.data.photo_it"
                            :key="'photo' + program.id"
                            alt
                            class="img-thumbnail"
                          />
                        </div>
                        <div class="col">
                          <ul>
                            <li>
                              <small
                                >Original:
                                <a
                                  :href="program.data.photo_it"
                                  target="_blank"
                                  >{{ program.data.photo_it }}</a
                                ></small
                              >
                            </li>
                            
                            
                          </ul>
                          <div
                            class="d-grid gap-2 mt-2"
                            v-if="program.type != 'new'"
                          >
                            <label for="formFile" class="form-label"
                              >Change photo:</label
                            >
                            <div class="row">
                              <div class="col">
                                <input
                                  class="form-control"
                                  type="file"
                                  id="newPhotoIT"
                                />
                                <progress
                                  v-if="saving_photo"
                                  id="progress"
                                  :value="uploadValue"
                                  max="100"
                                ></progress>
                              </div>
                              <div class="col d-grid gap-2">
                                <button
                                  class="btn btn-primary"
                                  type="button"
                                  id="uploadPhotoIT"
                                  @click="transferPhotoIT()"
                                >
                                  <span
                                    v-if="saving_photo_it"
                                    class="spinner-grow spinner-grow-sm"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                  Update photo IT
                                </button>
                                <div class="alert alert-secondary" role="alert">
                                  <i class="fas fa-exclamation-circle"></i>
                                  Dimension 1080x1440
                                </div>
                              </div>
                              <div class="col">
                                <div
                                  v-if="program.data.photo_it != null"
                                  class="d-grid gap-2"
                                >

                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr/>
                    <div class="container alert alert-warning">
                      <div class="row">
                        <div class="col-auto">
                          <video width="200" height="240" controls>
                            <source :src="program.data.video_it" type="video/mp4">
                          </video>
                          
                        </div>
                        <div class="col">
                          VIDEO IT
                          <ul>
                            <li>
                              <small
                                >Original:
                                <a
                                  :href="program.data.video_it"
                                  target="_blank"
                                  >{{ program.data.video_it }}</a
                                ></small
                              >
                            </li>
                            
                            
                          </ul>
                          <div
                            class="d-grid gap-2 mt-2"
                            v-if="program.type != 'new'"
                          >
                            <label for="formFile" class="form-label"
                              >Change video it:</label
                            >
                            <div class="row">
                              <div class="col">
                                <input
                                  class="form-control"
                                  type="file"
                                  id="newVideoIT"
                                />
                                <progress
                                  v-if="saving_photo"
                                  id="progress"
                                  :value="uploadValue"
                                  max="100"
                                ></progress>
                              </div>
                              <div class="col d-grid gap-2">
                                <button
                                  class="btn btn-primary"
                                  type="button"
                                  id="uploadVideo"
                                  @click="transferVideoIT()"
                                >
                                  <span
                                    v-if="saving_video_it"
                                    class="spinner-grow spinner-grow-sm"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                  Update video IT
                                </button>
                                <div class="alert alert-secondary" role="alert">
                                  <i class="fas fa-exclamation-circle"></i>
                                  Dimension 1080x1440
                                </div>
                              </div>
                              <div class="col">
                                <div
                                  v-if="program.data.video_it != null"
                                  class="d-grid gap-2"
                                >

                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr/>
                    <div>
                      <label for="example-input"
                        >Date de publication (Y-m-d)</label
                      >
                      <input type="date" class="form-control" id="date" :value="timestampToDate(program.data.date)" @change="setTimestampDate()">
                    </div>
                    <div class="alert alert-secondary mt-3" role="alert">
                      <div class="container">
                        <div class="row">
                          <div class="col">
                            <label for="niveau">Niveaux</label><br />
                            <b-form-select
                              v-model="program.data.niveaux"
                              :options="niveaux"
                            ></b-form-select>
                          </div>
                          <div class="col">
                            <b-form-group label="Materiel:">
                              <b-form-checkbox-group
                                id="materiel_program"
                                v-model="program.data.materiel_training"
                                :options="materiels"
                                name="flavour-1"
                              ></b-form-checkbox-group>
                            </b-form-group>
                          </div>

                          <div class="col">
                            <b-form-group label="Muscles:">
                              <b-form-checkbox-group
                                id="muscles_program"
                                v-model="program.data.muscles_training"
                                :options="muscles"
                                name="flavour-1"
                              ></b-form-checkbox-group>
                            </b-form-group>
                          </div>

                          <div class="col">
                            <b-form-group label="Objectifs:">
                              <b-form-checkbox-group
                                id="objectifs_program"
                                v-model="program.data.objectif_training"
                                :options="objectifs"
                                name="flavour-1"
                              ></b-form-checkbox-group>
                            </b-form-group>
                          </div>

                          <div class="col">
                            <div class="row">
                              <label>Durée programme:</label>
                              <b-form-input
                                type="number"
                                v-model="program.data.duree"
                                id="duree_programme"
                                placeholder="Durée du programme (en jours)"
                              ></b-form-input>
                            </div>
                            <div class="row">
                              <label>Jours training:</label>
                              <b-form-input
                                type="number"
                                v-model="program.data.jours_entrainements"
                                id="jours_entrainements_program"
                                placeholder="Jours training"
                              ></b-form-input>
                            </div>
                            <div class="row">
                              <label>Jours repos:</label>
                              <b-form-input
                                type="number"
                                v-model="program.data.jours_repos"
                                id="jours_repos_program"
                                placeholder="Jours repos"
                              ></b-form-input>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <hr />
                    <div class="container px-0 py-2">
                      <div class="alert alert-info text-center" role="alert">
                        <i class="fas fa-magic"></i> Creator Tool
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <h5>Days:</h5>
                          <hr />
                          <table class="table">
                            <thead>
                              <tr>
                                <th scope="col">#</th>
                                <th scope="col">Type</th>
                                <th scope="col">Workout</th>
                                <th scope="col">Delete</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(day, key) in program.data.days"
                                :key="'dayprogram' + key"
                              >
                                <th scope="row">Day {{ key + 1 }}</th>
                                <td>
                                  <b-form-select
                                    v-model="day.type_journee"
                                    :options="optionsTypeDay"
                                  ></b-form-select>
                                </td>
                                <td>
                                  <b-form-select
                                    v-if="day.type_journee != 'RESTDAY'"
                                    v-model="day.workout"
                                    :options="workouts"
                                  ></b-form-select>
                                </td>
                                <td>
                                   <i
                                              class="fas fa-trash-alt"
                                              @dblclick="deleteDayList(key)"
                                            ></i>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <div class="d-grid gap-2 mt-4">
                            <button
                              class="btn btn-warning"
                              type="button"
                              @click="addDayProgram()"
                            >
                              + Add day
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
